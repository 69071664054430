@keyframes loading {
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

.hidden, .pre-footer {
  display: none; }

@keyframes hippyshake {
  0% {
    transform: translateX(2px); }
  20% {
    transform: translateX(0); }
  40% {
    transform: translateX(-2px); }
  60% {
    transform: translateX(0); }
  80% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0); } }

.hero .hero-inner {
  background: rgba(18, 86, 135, 0.7);
  padding: 0px; }
  .hero .hero-inner .company-logo {
    padding: 1.5rem;
    background: #fff;
    width: 100%;
    max-width: 100%; }
    .hero .hero-inner .company-logo img {
      max-width: 350px;
      display: block;
      margin: auto; }
    @media only screen and (min-width: 60em) {
      .hero .hero-inner .company-logo {
        width: 45%;
        max-width: 45%; } }
  .hero .hero-inner .home-hero-text {
    display: block;
    clear: both;
    background: rgba(18, 86, 135, 0.7);
    padding: 1.5rem 3rem;
    color: #fff;
    margin-bottom: 0px; }
    @media only screen and (min-width: 60em) {
      .hero .hero-inner .home-hero-text {
        width: 45%; } }
    .hero .hero-inner .home-hero-text .home-hero-title {
      font-size: 1.4rem;
      margin-bottom: 1.5rem; }

.main {
  padding: 0px;
  background: none;
  margin-bottom: 3rem; }
  .main .centre {
    padding: 0px; }
  .main .testimonial-rotator {
    width: 100%;
    background: rgba(18, 86, 135, 0.7);
    padding: 1.5rem 3rem;
    margin-bottom: 0px; }
    @media only screen and (min-width: 60em) {
      .main .testimonial-rotator {
        width: 45%; } }
    .main .testimonial-rotator .block-header .title {
      color: #fff; }
    @media only screen and (min-width: 50em) {
      .main .testimonial-rotator .block-header {
        width: 100%; } }
    .main .testimonial-rotator .content {
      color: #fff;
      border-left: 0px; }
      @media only screen and (min-width: 50em) {
        .main .testimonial-rotator .content {
          width: 100%;
          padding-left: 0px; } }
      .main .testimonial-rotator .content li {
        color: #fff;
        font-size: 1.4rem; }
    .main .testimonial-rotator .bx-controls {
      position: relative; }
      .main .testimonial-rotator .bx-controls .bx-pager-item .bx-pager-link.active, .main .testimonial-rotator .bx-controls .bx-pager-item .bx-pager-link:hover {
        background: rgba(255, 255, 255, 0.5); }

.home-block {
  display: block;
  color: #fff;
  padding: 1.5rem;
  text-align: center;
  margin-bottom: 0px;
  position: relative; }
  @media only screen and (min-width: 32.5em) {
    .home-block {
      width: 50%;
      float: left;
      height: 300px; } }
  @media only screen and (min-width: 60em) {
    .home-block {
      position: absolute;
      top: 0;
      float: none;
      width: 27.5%;
      height: 100%; } }
  .home-block .title {
    color: #fff;
    text-transform: uppercase;
    font-size: 0.72rem;
    font-weight: 700;
    text-align: center;
    display: inline-block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-bottom: 1.5rem;
    margin-bottom: 0px; }
    .home-block .title:before {
      content: url(/images/icons/logo-icon.png);
      display: block;
      margin: auto;
      height: 50px;
      width: 50px;
      margin-bottom: 0.75rem; }
  .home-block .content {
    padding: 1.5rem;
    font-size: 1rem;
    text-align: center; }
    .home-block .content a {
      color: #fff;
      text-align: center; }
      .home-block .content a:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        background: rgba(255, 255, 255, 0.3);
        transition: all 0.3s ease-out; }
      .home-block .content a:hover:after {
        opacity: 1; }
  .home-block.home-block-offices {
    background: #c11617;
    margin-bottom: 5rem; }
    @media only screen and (min-width: 50em) {
      .home-block.home-block-offices {
        margin-bottom: 0; } }
    @media only screen and (min-width: 60em) {
      .home-block.home-block-offices {
        right: 0px;
        margin-bottom: 0; } }
  .home-block.home-block-services {
    background: #125687; }
    @media only screen and (min-width: 60em) {
      .home-block.home-block-services {
        right: calc(55%/2); } }

.wrapper {
  position: relative;
  z-index: 1; }
  .wrapper:before, .wrapper:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }
  .wrapper:before {
    filter: grayscale(1);
    transition: filter 0.5s ease-out;
    z-index: -2; }
  .wrapper:after {
    opacity: 1;
    filter: grayscale(1);
    transition: opacity 0.5s ease-out, filter 0.5s ease-out;
    z-index: -1; }
  .wrapper.home-image-1:before {
    background-image: url(/images/home/home-banner-1.jpg); }
  .wrapper.home-image-1:after {
    background-image: url(/images/home/home-banner-2.jpg); }
  .wrapper.home-image-1.active:before {
    filter: grayscale(0);
    transition: filter 0.5s ease-out 2.5s; }
  .wrapper.home-image-1.active:after {
    opacity: 0;
    filter: grayscale(1);
    transition: opacity 0s ease-out; }
  .wrapper.home-image-1:before {
    background-position: top center; }
  .wrapper.home-image-2:before {
    background-image: url(/images/home/home-banner-2.jpg); }
  .wrapper.home-image-2:after {
    background-image: url(/images/home/home-banner-3.jpg); }
  .wrapper.home-image-2.active:before {
    filter: grayscale(0);
    transition: filter 0.5s ease-out 2.5s; }
  .wrapper.home-image-2.active:after {
    opacity: 0;
    filter: grayscale(1);
    transition: opacity 0s ease-out; }
  .wrapper.home-image-1:before {
    background-position: top center; }
  .wrapper.home-image-3:before {
    background-image: url(/images/home/home-banner-3.jpg); }
  .wrapper.home-image-3:after {
    background-image: url(/images/home/home-banner-1.jpg); }
  .wrapper.home-image-3.active:before {
    filter: grayscale(0);
    transition: filter 0.5s ease-out 2.5s; }
  .wrapper.home-image-3.active:after {
    opacity: 0;
    filter: grayscale(1);
    transition: opacity 0s ease-out; }
  .wrapper.home-image-1:before {
    background-position: top center; }
