
//==========  Import modules  ==========//

@import '../modules/global/config/_index';
@import '../modules/global/utils/_index';
@import '../modules/global/layouts/_index';


.hero {

	.hero-inner {
		background: rgba($color-tertiary, 0.7);
		padding: 0px;

		.company-logo {
			padding: $block-padding;
			background: $color-white;
			width: 100%;
			max-width: 100%;

			img {
				max-width: 350px;
				display: block;
				margin: auto;
			}

			@media #{$tabletXL} {
				width: 45%;
				max-width: 45%;
			}
		}

		.home-hero-text {
			display: block;
			clear: both;
			background: rgba($color-tertiary, 0.7);
			padding: $block-padding $block-margin;
			color: $color-white;
			margin-bottom: 0px;

			@media #{$tabletXL} {
				width: 45%;
			}

			.home-hero-title {
				font-size: $h4;
				margin-bottom: $block-padding;
			}
		}
	}
}

.main {
	padding: 0px;
	background: none;
	margin-bottom: $block-margin;
	
	.centre {
		padding: 0px;
	}

	.testimonial-rotator {
		width: 100%;
		background: rgba($color-tertiary, 0.7);
		padding: $block-padding $block-padding * 2;
		margin-bottom: 0px;

		@media #{$tabletXL} {
			width: 45%;
		}

		.block-header {
			
			.title {
				color: $color-white;
			}

			@media #{$tablet} {
				width: 100%;
			}
		}

		.content {
			color: $color-white;
			border-left: 0px;
	
			@media #{$tablet} {
				width: 100%;
				padding-left: 0px;
			}

			li {
				color: $color-white;
				font-size: $h4;
			}
		}

		.bx-controls {
			position: relative;

			.bx-pager-item {

				.bx-pager-link {

					&.active, &:hover {
						background: rgba($color-white, 0.5);
					}
				}
			}
		}
	} 
}

.home-block {
	display: block;
	color: $color-white;
	padding: $block-padding;
	text-align: center;
	margin-bottom: 0px;
	position: relative;


	@media #{$mobileL} {
		width: 50%;
		float: left;
		height: 300px;
	}

	@media #{$tabletXL} {
		position: absolute;
		top: 0;
		float: none;
		width: ( 55% / 2 );
		height: 100%;
	}

	.title {
		color: $color-white;
		text-transform: uppercase;
		font-size: $font-size-body*0.8;
		font-weight: $font-bold;
		text-align: center;
		display: inline-block;
		border-bottom: 1px solid rgba($color-white, 0.5);
		padding-bottom: $block-padding;
		margin-bottom: 0px;

		&:before {
			content: url(/images/icons/logo-icon.png);
			display: block;
			margin: auto;
			height: 50px;
			width: 50px;
			margin-bottom: $block-padding/2;
		}
	}

	.content {
		padding: $block-padding;
		font-size: $h6;
		text-align: center;

		a {
			color: $color-white;
			text-align: center;
			
			&:after {
				content: '';
				position: absolute;
				height: 100%;
				width: 100%;
				top: 0; left: 0;
				opacity: 0;
				background: rgba($color-white, 0.3);
				transition: $transition;
			}

			&:hover:after {
				opacity: 1;
			}
		}
	}

	&.home-block-offices {
		background: $color-secondary;
		margin-bottom: $block-margin + 2rem;
		
		@media #{$tablet} {
			margin-bottom: 0;
		}

		@media #{$tabletXL} {
			right: 0px;
			margin-bottom: 0;
		}
	}

	&.home-block-services {
		background: $color-tertiary;

		@media #{$tabletXL} {
			right: calc(55%/2);
		}
	}
}

.pre-footer {
	@extend .hidden;
}



.wrapper {
	position: relative;
	z-index: z(front);
	
	&:before,
	&:after {
		content: '';
		@include cover(absolute);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	&:before {
		filter: grayscale(1);
		transition: filter 0.5s ease-out;
		z-index: z(back) - 1;
	}

	&:after {
		opacity: 1;
		filter: grayscale(1);
		transition: opacity 0.5s ease-out,
					filter 0.5s ease-out;
		z-index: z(back);
	}

	@for $i from 1 through 3
	{
		$next: if($i == 3, 1, ($i + 1));

		&.home-image-#{$i} {
			
			&:before {
				background-image: url(/images/home/home-banner-#{$i}.jpg);
			}

			&:after {
				background-image: url(/images/home/home-banner-#{$next}.jpg);
			}

			&.active {

				&:before {
					filter: grayscale(0);
					transition: filter 0.5s ease-out 2.5s;
				}

				&:after {
					opacity: 0;
					filter: grayscale(1);
					transition: opacity 0s ease-out;
				}
			}
		}
		&.home-image-1 {
			&:before {
				background-position: top center;
			}
		}
	}
}
